import { map, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Page } from '../types/page';
import { Accrual, AccrualCode, AccrualFlagsDto } from '../types/accrual';
import { SearchOptionsDTO } from '../types/search-options';
import { Credit } from '../types/credit';

@Injectable()
export class CreditAccrualsService {
  public accrualUpdated: Subject<boolean> = new Subject<boolean>();

  constructor(private request: RequestService) { }

  updateAccrualFlags(
    creditId: number,
    flags: AccrualFlagsDto
  ): Observable<Credit> {
    return this.request.put(['accruals', creditId, 'flags'], {
      body: flags,
    });
  }


  getAccrualsList(options: SearchOptionsDTO): Observable<Page<Accrual>> {
    return this.request.get(['accruals'], { params: options });
  }

  saveAccrual(payload: Partial<Accrual>): Observable<Accrual> {
    return this.request.post(['accruals'], {
      body: payload,
    });
  }

  mapKeyToCode(component: string): AccrualCode {
    switch (component) {
      case 'interest':
        return AccrualCode.INTEREST;
      case 'forfeit':
        return AccrualCode.FORFEIT;
      case 'utilizationFee':
        return AccrualCode.UTILIZATION_FEE;
      case 'overduePenalty':
      case 'penalty':
        return AccrualCode.OVERDUE_PENALTY;
      case 'courtFee':
        return AccrualCode.COURT_FEE;
      case 'consultantFee':
        return AccrualCode.CONSULTANT_FEE;
      case 'earlyRepaymentFee':
        return AccrualCode.EARLY_REPAYMENT_FEE;
      default:
        return undefined;
    }
  }

  mapCodeToTypeId(code: AccrualCode): number {
    switch (code) {
      case AccrualCode.INTEREST:
        return 1;
      case AccrualCode.FORFEIT:
        return 2;
      case AccrualCode.OVERDUE_PENALTY:
        return 3;
      case AccrualCode.UTILIZATION_FEE:
        return 4;
      case AccrualCode.COURT_FEE:
        return 5;
      case AccrualCode.CONSULTANT_FEE:
        return 6;
      case AccrualCode.EARLY_REPAYMENT_FEE:
        return 7;
      default:
        return 0;
    }
  }
}
