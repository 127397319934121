import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EventLogService } from '../../../core/services/event-log.service';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';
import { EventLogHistoryType, EventLogSubheader } from '@app/main/main-layout/right-drawer/shared/event-log.enums';
import { RightDrawerService } from '@app/main/main-layout/right-drawer/right-drawer.service';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { EventLogState } from '@app/main/main-layout/right-drawer/shared/state.interfaces';
import { Credit } from '@app/core/types/credit';
@Component({
  selector: 'itfg-search-in-event-log-button',
  templateUrl: './search-in-event-log-button.component.html',
  styleUrls: ['./search-in-event-log-button.component.scss'],
})
export class SearchInEventLogButtonComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  currentlyOpenedCredit: Credit;
  currentlyOpenedSubheader: EventLogSubheader;
  currentlyOpenedHistoryType: EventLogHistoryType;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private eventLogService: EventLogService,
    private eventLogStateService: EventLogStateService,
    private rightDrawer: RightDrawerService,

  ) { }

  ngOnInit() {
    this.eventLogStateService
      .getEventLogState()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((state: EventLogState) => {
        this.handleEventLogState(state);
      });
  }

  handleEventLogState(state: EventLogState) {
    const {
      openedCredit,
      subheader,
      historyType,
    } = state;

    this.currentlyOpenedCredit = openedCredit;
    this.currentlyOpenedSubheader = subheader;
    this.currentlyOpenedHistoryType = historyType;
  }

  searchInEventLog() {
    this.eventLogStateService.setState({
      historyType: this.currentlyOpenedHistoryType ? this.currentlyOpenedHistoryType : this.currentlyOpenedCredit ? EventLogHistoryType.CREDIT : EventLogHistoryType.PHONE,
      subheader: this.currentlyOpenedSubheader ? this.currentlyOpenedSubheader : null,
    });

    if (this.rightDrawer.drawer.opened) {
      this.eventLogService.searchInEventLog.next(this.control.value);
    } else {
      this.rightDrawer.open();

      this.rightDrawer.drawer.openedChange
        .pipe(
          filter((isOpen) => isOpen),
          take(1)
        )
        .subscribe(() => {
          this.eventLogService.searchInEventLog.next(this.control.value);
        });
    }
  }

  ngOnDestroy(): void {
    this.eventLogService.searchInEventLog.next(null);
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
