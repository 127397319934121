import { Credit } from './credit';

export enum InstallmentStatusType {
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  NOT_MATURED = 'NOT_MATURED',
  MATURES_SOON = 'MATURES_SOON',
  MATURES_TODAY = 'MATURES_TODAY',
}

export interface Installment {
  accrualsStartDate: string;
  accruedForfeit: number;
  accruedInterest: number;
  accruedUtilizationFee: number;
  accruedPenalty: number;
  credit: Credit;
  forfeit: number;
  id: number;
  interest: number;
  maturityDate: string;
  number: number;
  paymentDate: string;
  principal: number;
  total: number;
  updateTime: string;
  utilizationFee: number;
  overdueDays: number;
  totalDeducted?: number;
  forfeitDocDeadline?: string;
}
