import { Pipe, PipeTransform } from '@angular/core';
import {
  EventLogAuditDiff,
  EventLogAuditType,
  EventLogAudit,
} from '../../../core/types/event-log.type';
import { TranslateService } from '@ngx-translate/core';
import {
  CreditStatusNames,
  CreditStatusReasons,
} from '../../../core/types/credit-status';
import { Utils } from '../../../core/utils/utils';
import { ClientStatusNames } from '../../../core/types/client-status';
import { PaymentMethodTypes } from '../../../core/types/payment-method';
import {PaymentAgreementStatusNames} from '../../../core/types/payment-agreement';

@Pipe({
  name: 'editAuditDisplayText',
})
export class EditAuditDisplayTextPipe implements PipeTransform {
  static readonly auditFieldTranslationMap: { [key: string]: any } = {
    [EventLogAuditType.USER]: 'clients',
    [EventLogAuditType.CREDIT]: 'credits',
    [EventLogAuditType.CONVERSATION]: 'communication',
    [EventLogAuditType.CONTACTPHONE]: 'clients',
    [EventLogAuditType.CCC]: 'ccr',
    [EventLogAuditType.COLLECTION]: 'collection',
    [EventLogAuditType.BANKACCOUNT]: 'bankAccounts',
    [EventLogAuditType.PAYMENTAGREEMENT]: 'collection',
    [EventLogAuditType.CALL]: 'call',
    [EventLogAuditType.NOTE]: 'note',
  };

  private auditVallueDiffMap: { [key: string]: any } = {
    credit_status: (status: CreditStatusNames) =>
      this.auditValueToTranslationKey(
        'credits.creditStatusList',
        status.toLowerCase()
      ),
    agreement_status: (status: PaymentAgreementStatusNames) =>
      this.auditValueToTranslationKey(
        'collection.paymentAgreementNames',
        status.toLowerCase()
      ),
    status_name: (status: ClientStatusNames) =>
      this.auditValueToTranslationKey('global', status.toLowerCase()),
    credit_status_reason: (statusReason: CreditStatusReasons) => {
      return this.auditValueToTranslationKey(
        'credits.creditStatusReasonList',
        statusReason ? statusReason.toLowerCase() : 'noReason'
      );
    },
    mobile_confirmed: this.booleanToTranslationKey,
    is_verified: this.booleanToTranslationKey,
    email_confirmed: this.booleanToTranslationKey,
    forfeit_accruals_enabled: this.booleanToTranslationKey,
    full_rate_accruals: this.booleanToTranslationKey,
    payment_preference: (paymentPreference: PaymentMethodTypes) =>
      this.auditValueToTranslationKey('payments.methods', paymentPreference),
    id_card_verified: this.idStatusToTranslationKey,
    face_status: (status: CreditStatusNames) => {
      return `verification.verificationStatus.${status.toLowerCase()}`
    },
    face_status_reason: (statusReason: CreditStatusReasons) => {
      return this.auditValueToTranslationKey(
        'verification.verificationStatusReasonList',
        statusReason ? statusReason.toLowerCase() : 'noReason'
      );
    },
    id_card_status: (status: CreditStatusNames) => {
      return `verification.verificationStatus.${status.toLowerCase()}`
    },
    id_card_status_reason: (statusReason: CreditStatusReasons) => {
      return this.auditValueToTranslationKey(
        'verification.verificationStatusReasonList',
        statusReason ? statusReason.toLowerCase() : 'noReason'
      );
    },
  };

  constructor(private translateService: TranslateService) {}

  transform(
    diff: EventLogAuditDiff,
    prop?: string,
    audit?: EventLogAudit
  ): any {
    const translationMap = this.auditVallueDiffMap;

    const translate = {
      field: (field: string) => {
        const translationKey = this.transformFieldToTranslationKey(
          audit.type,
          field
        );
        return this.translateService.instant(translationKey);
      },
      value: (value: any) => {
        const translatedValue = translationMap[diff.field]
          ? this.translateService.instant(translationMap[diff.field](value))
          : value;
        return !translatedValue ? JSON.stringify('') : translatedValue;
      },
    };

    diff = {
      field: translate.field(diff.field),
      oldValue: translate.value(diff.oldValue),
      newValue: translate.value(diff.newValue),
    };

    return prop ? diff[prop] : diff;
  }

  transformFieldToTranslationKey(type: EventLogAuditType, value: string) {
    const fieldMap = EditAuditDisplayTextPipe.auditFieldTranslationMap;
    return fieldMap[type]
      ? fieldMap[type] + `.${Utils.snakeCaseToCamelCase(value)}`
      : value;
  }

  auditValueToTranslationKey(partial, key) {
    return `${partial}.${Utils.snakeCaseToCamelCase(key)}`;
  }

  booleanToTranslationKey(value: boolean) {
    return value ? 'global.yes' : 'global.no';
  }
  idStatusToTranslationKey (value) {
    if (value === null) {
      return 'global.unverified';
    } else {
      return value ? 'global.valid' : 'global.invalid';
    }
  }
}
