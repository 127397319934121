import { CollectionRequestService } from './collection-request.service';
import { Observable, of } from 'rxjs';
import { Page } from '../types/page';
import { Collector, CollectorOperator } from '../types/collector';
import { SearchOptionsDTO, SearchOptions } from '../types/search-options';
import { map, mergeMap } from 'rxjs/operators';
import { BrandService } from './brand.service';
import { AdministratorService } from './administrator.service';
import { SearchOperations } from '../types/search-criterium';
import { CollectorCredit } from '../types/credit';
import { CreditAssignment } from '../types/credit-assignment';
import { Administrator } from '../types/administrator';
import { ReminderService } from './reminder.service';
import { Reminder } from '../types/reminder';
import { Injectable } from '@angular/core';
import { CollectionOperator } from '../types/collection-operator.type';
import { CollectionOperatorCategory } from '../types/collection-operator-category.type';
import { PaymentPromiseService } from './payment-promise.service';

@Injectable()
export class CollectionService {
  constructor(
    private request: CollectionRequestService,
    private brandService: BrandService,
    private adminService: AdministratorService,
    private reminderService: ReminderService,
    private paymentPromiseService: PaymentPromiseService,
  ) { }

  getMatchingCollectionAgents$(creditId: number) {
    return this.request.get(['collection-agents', 'matching', creditId]);
  }

  getgCollectionAgents$() {
    return this.request.get(['collection-agents']);
  }

  getCollectionOperators$() {
    return this.request.get(['operators', 'collection-operators']);
  }

  getGroupedCollectionOperators$() {
    return this.request.get(['operators', 'groups']);
  }

  getCollectorOperatorById$(id: string | number) {
    return this.request.get(['operators', id, 'collector']);
  }

  orderCollectionAgents$(orderList: number[]) {
    return this.request.post(['collection-agents', 'order'], {
      body: orderList,
    });
  }

  saveCollectionOperator$(
    operator: CollectionOperator
  ): Observable<CollectionOperator> {
    return this.request.post(['operators'], {
      body: operator,
    });
  }

  getCollectorList$(options?: SearchOptionsDTO): Observable<Page<Collector>> {
    return this.request.get(['collectors'], { params: options }).pipe(
      mergeMap((page: Page<Collector>) => {
        const brands$ = this.brandService.getBrandList();
        return this.transformPage$(brands$, page, 'brandId', 'brand');
      })
    );
  }

  getCollectorOperators$(options?: SearchOptionsDTO) {
    const bucketOperators$ = this.request.get(['operators', 'collector'], {
      params: options,
    }).pipe(
      mergeMap((collectorOperatorPage: Page<CollectorOperator>) => {
        const collectorOperators = collectorOperatorPage.content;

        const searchOptions = new SearchOptions();
        searchOptions.pageSize = 1000;
        searchOptions.criteria = [
          {
            key: 'active',
            operation: SearchOperations.EQUALS,
            value: true,
          },
          {
            key: 'id',
            operation: SearchOperations.IN,
            value: collectorOperators.map((collectorOperator) => collectorOperator.external_operator_id),
          }
        ];

        return this.adminService.getAdministratorList(searchOptions.getDTO()).pipe(
          map((activeAdministratorsPage: Page<Administrator>) => {
            const activeOperatorIds = activeAdministratorsPage.content.map(operator => operator.id);

            const activeCollectorOperators = collectorOperators.filter(collectorOperator =>
              activeOperatorIds.includes(collectorOperator.external_operator_id)
            );

            return { ...collectorOperatorPage, content: activeCollectorOperators };
          })
        );
      })
    );
    return bucketOperators$;
  }

  getCollectioOperators$(
    options?: SearchOptions
  ): Observable<CollectionOperator[]> {
    return this.request.get(['operators'], {
      params: options.getDTO(),
    });
  }

  getCollectionAgentOperatorsByAssignment$(
    assignment: CreditAssignment
  ): Observable<CollectionOperator[]> {
    const options = new SearchOptions();
    options.addCriterium({
      key: 'category',
      operation: SearchOperations.EQUALS,
      value: assignment.operator.category,
    });
    options.addCriterium({
      key: 'instance_id',
      operation: SearchOperations.EQUALS,
      value: assignment.operator.instance_id,
    });
    return this.request.get(['operators'], {
      params: options.getDTO(),
    });
  }

  getCollectorCreditsWithReminders$(
    options?: any
  ): Observable<Page<CollectorCredit>> {
    return this.getCollectorCredits$(options);
  }

  getCollectorCredits$(options?: any): Observable<Page<CollectorCredit>> {
    return this.request.get(['credit-assignments', 'collector-credits'], {
      params: options,
    })
      .pipe(
        map((page: Page<CollectorCredit>) => {
          page.content.map(bc => {
            if (bc.paymentPromise) {
              bc.paymentPromise = this.paymentPromiseService.paymentPromiseStatusMap(bc.paymentPromise);
            }
            return bc;
          });
          return page;
        }),
      );
  }

  getUnassignedCredits$(options?: any) {
    return this.request.get(['credit-assignments', 'unassigned'], {
      params: options,
    });
  }

  mapItem(list: any[], entity: any, oldKey: string, newKey: string): any {
    const target = list.find(item => item.id === entity[oldKey]);
    const mappedEntity = {
      ...entity,
      [newKey]: target,
    };
    return mappedEntity;
  }

  transformPage$(
    observable$: Observable<any[]>,
    itemList: Page<any>,
    oldKey: string,
    newKey: string,
    entityPath?: string
  ): Observable<Page<any>> {
    return observable$.pipe(
      map(brands => {
        itemList.content = itemList.content.map(item => {
          const isNestedEntity = !!item[entityPath];

          if (isNestedEntity) {
            item[entityPath] = this.mapItem(
              brands,
              item[entityPath],
              oldKey,
              newKey
            );
          } else {
            item = this.mapItem(brands, item, oldKey, newKey);
          }
          return item;
        });

        return itemList;
      })
    );
  }

  mapCollectorOperators$(operators: Page<CollectorOperator>) {
    const brands$ = this.brandService.getBrandList();
    return this.transformPage$(
      brands$,
      operators,
      'brandId',
      'brand',
      'collector'
    );
  }

  getCreditActiveAssignment$(creditId: number) {
    return this.request.get(['credit-assignments', 'credits', creditId]).pipe(
      mergeMap((assignment: CreditAssignment) => {
        if (!assignment || !assignment.operator.external_operator_id) {
          return of(assignment);
        }
        return this.adminService
          .getAdministratorById(assignment.operator.external_operator_id)
          .pipe(
            map((admin: Administrator) => {
              assignment['assignee'] = admin;
              return assignment;
            })
          );
      })
    );
  }

  getCreditsActiveAssignments$(creditIds: number[]) {
    return this.request.get(['credit-assignments', 'credits', 'bulk'], {
      params: {
        creditIds: JSON.stringify(creditIds)
      }
    });
  }

  getLegalAssignments$(options?: any) {
    return this.request.get(['credit-assignments', 'legal-collection'], {
      params: options,
    });
  }

  getExternalAssignments$(options?: any) {
    return this.request.get(['credit-assignments', 'external-collection'], {
      params: options,
    });
  }

  sendTo$(creditId: number, collectionAgentId: number) {
    return this.request.post(['credit-assignments', 'send'], {
      body: {
        creditId,
        collectionAgentId,
      },
    });
  }

  sendAndAssignBulk$(creditIds: number[], operatorId: number) {
    return this.request.post(['credit-assignments', 'assign-bulk'], {
      body: {
        creditIds,
        operatorId,
      },
    });
  }

  sendWithoutAssigningBulk$(creditIds: number[], operatorId: number) {
    return this.request.post(['credit-assignments', 'send-bulk'], {
      body: {
        creditIds,
        operatorId,
      },
    });
  }

  updateAssignedOperator$(creditId: number, operatorId: number) {
    return this.request.post(
      ['credit-assignments', 'credits', creditId, 'assignee'],
      {
        body: {
          operatorId: operatorId,
        },
      }
    );
  }

  unassign$(creditId: number) {
    return this.request.post(['credit-assignments', 'unassign', creditId]);
  }

  getAssignmentHistoryLog$(creditId: number, userId?: number) {
    return this.request.get(['credits', creditId, 'event-log'], {
      params: userId ? { userId } : {}
    });
  }

  getOperatorCategories$(): Observable<string[]> {
    return this.request.get(['operators', 'categories']);
  }

  getOperatorsInstances$() {
    return this.request.get(['operators', 'instances']);
  }
}
