@if(report && reportListData.length > 0) {

<div>
  <itfg-reports-info-panel [listData]="reportListData"
                           [reportData]="report"
                           [reportTime]="requestTime"
                           [requestTime]="requestTime"
                           [requesterName]="report.requesterName"
                           (dataChanged)="dataChanged($event)"></itfg-reports-info-panel>
</div>

@if (report.data) {
<div layout="row"
     flex="100">
  <mat-card flex="33">
    <mat-card-content>
      <mat-list>
        <div mat-subheader>Резултат</div>

        <div class="document-row">
          <mat-list-item>
            @if (report.data.event === 'verification.accepted') {
            <mat-icon matListItemIcon
                      class="verified">check_circle</mat-icon>
            }
            @if (report.data.event === 'verification.declined' || report.data.event === 'verification.cancelled') {
            <mat-icon matListItemIcon
                      class="not-verified">cancel</mat-icon>
            }
            <span matListItemTitle>{{'reports.identityVerification.statusLabel'|translate}}</span>
            <span matListItemLine
                  matTooltip=>{{ ('reports.identityVerification.status.' + report.data.event) | translate }}{{
              report.data.declined_reason ? " - " + report.data.declined_reason : ''}}</span>
          </mat-list-item>
        </div>
        <div class="document-row">
          <mat-list-item>
            <mat-icon matListItemIcon>description</mat-icon>
            <span matListItemTitle>{{ 'reports.identityVerification.matchConfidence' | translate }}</span>
            @if (report.data?.verification_data?.document?.face_match_confidence) {
              <span>{{ report.data?.verification_data?.document?.face_match_confidence }}%</span>
            } @else {
              <span>-</span>
            }
          </mat-list-item>
        </div>
        <div class="document-row">
          <mat-list-item>
            <mat-icon matListItemIcon>description</mat-icon>
            <span matListItemTitle>{{ 'reports.identityVerification.idCardNumberLabel' | translate }}</span>
            @if (report.data?.verification_data?.document?.document_number) {
              <span>{{ report.data?.verification_data?.document?.document_number }}</span>
            } @else {
              <span>-</span>
            }
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <mat-card flex>
    <mat-card-content>
      <div class="documents-list-container"
           flex>
        @if (report.documents.length > 0) {
        <div flex="50">
          <mat-selection-list [multiple]="false"
                              #documentList
                              [formControl]="documentsControl">
            <div mat-subheader>{{'reports.identityVerification.documentSectionName'|translate}}</div>

            @for (document of report.documents; track document) {
            <div class="document-row">
              <mat-list-option [value]="document">
                <mat-icon matListItemIcon>image</mat-icon>
                <div matListItemTitle>
                  <span>{{ ('reports.identityVerification.documentType.' + document.type.name) | translate }}</span>
                </div>
                <div matListItemLine>{{ document.createdAt | date }} - {{document.name}}</div>
              </mat-list-option>
            </div>
            }

          </mat-selection-list>

          <div flex
               layout-align="end"
               (click)="downloadSelectedDocument()">
            <button mat-raised-button>
              {{ 'reports.identityVerification.downloadDocument' | translate }}
              <mat-icon>download</mat-icon>
            </button>
          </div>
        </div>


        } @else {
        <mat-list>
          <mat-list-item>
            <div matListItemLine>
              <span>{{'reports.identityVerification.noDocumentsMessage'|translate}}</span>
            </div>
          </mat-list-item>
        </mat-list>
        }

        @if (report.documents.length > 0) {
        <div class="document-preview"
             flex>
          @if(hoveredDocument?.url) {
          <img [src]="hoveredDocument.url" class="image-preview"/>
          }
        </div>
        }

      </div>
    </mat-card-content>
  </mat-card>
</div>
} @else {
<h3>{{'reports.identityVerification.noDataMessage'|translate}}.</h3>
}
} @else {
{{ 'reports.identityVerification.emptyReportsList' | translate }}
}
