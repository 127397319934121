import { Pipe, PipeTransform } from '@angular/core';
import { Insurance } from '@app/core/types/insurance';
import { CreditAssignment } from '@app/core/types/credit-assignment';
import { CollectionOperatorCategory } from '@app/core/types/collection-operator-category.type';
import { CreditStatusNames } from '@app/core/types/credit-status';

@Pipe({
  name: 'isInsuranceCancellable'
})
export class InsuranceCancellablePipe implements PipeTransform {
  transform(insurance: Insurance, activeAssignment?: CreditAssignment): boolean {
    if (!insurance) {
      return false;
    }

    const hasCertificate = !!insurance?.certificateId;
    const isNotCancelled = !insurance?.cancelledAt;
    const hasNoRepayment = !insurance?.credit?.repaymentDate;
    const isCreditActive = insurance?.credit?.creditStatus?.name === CreditStatusNames.REGULAR || insurance?.credit?.creditStatus?.name === CreditStatusNames.OVERDUE;
    

    const nonCancellableCategories = [
      CollectionOperatorCategory.legal,
      CollectionOperatorCategory.cession
    ];

    const isNotAssignedToNonCancellableCategory = !activeAssignment || 
      !nonCancellableCategories.includes(activeAssignment?.operator?.category as CollectionOperatorCategory);
    
    return hasCertificate && 
           isNotCancelled &&
           hasNoRepayment &&
           isNotAssignedToNonCancellableCategory && 
           isCreditActive;
  }
}