import { Pipe, PipeTransform } from '@angular/core';
import { AccrualKeysExtended } from '@app/core/types/accrual';
import * as moment from 'moment';

@Pipe({
  name: 'isAccrualCorrectable',
})
export class IsAccrualCorrectablePipe implements PipeTransform {
  transform(row: any, installment: any, receivables: any): boolean {
    const accrualStartDate = moment(installment?.accrualsStartDate);
    const startOfThisYear = moment().startOf('year');
    if (accrualStartDate.isBefore(startOfThisYear)) {
      return true;
    }
    if (row.receivables === 0 || row.receivables === row.paidTotal) {
      return true;
    }
    if (row.component === AccrualKeysExtended.TOTAL) {
      return receivables
        .filter(
          (receivable) =>
            receivable.component !== AccrualKeysExtended.TOTAL &&
            receivable.component !== AccrualKeysExtended.PRINCIPAL
        )
        .every(
          (receivable) =>
            receivable.receivables === 0 ||
            receivable.receivables === receivable.paidTotal
        );
    }
    return false;
  }
}
