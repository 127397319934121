import { Pipe, PipeTransform } from "@angular/core";
import { Credit } from "@app/core/types/credit";
import { Insurance } from "@app/core/types/insurance";
import { ToCustomItfgCurrencyPipe } from "@app/main/credit/pipes/to-custom-itfg-currency.pipe";
import { ItfgDateFormatPipe } from "./itfg-date-format.pipe";

export interface InsuranceStatus {
  tooltip: string;
  tooltipParams?: any;
  priceTooltip: string;
  priceTooltipParams: any;
  icon: string;
  color: string;
  needsCertificate: boolean;
}

@Pipe({
  name: 'insuranceStatus',
  pure: true
})
export class InsuranceStatusPipe implements PipeTransform {
  constructor(
    private currencyPipe: ToCustomItfgCurrencyPipe,
    private datePipe: ItfgDateFormatPipe
  ) {}

  transform(insurance: Insurance, credit: Credit): InsuranceStatus {
    if (!insurance) {
      return null;
    }

    const needsCertificate = !insurance.certificateId && 
                            credit?.confirmationTime && 
                            credit?.utilizationTime && 
                            !credit?.repaymentDate;

    const formattedCancelledAt = insurance.cancelledAt ? 
                                this.datePipe.transform(insurance.cancelledAt) : 
                                null;
    
    const formattedCancellationRequestedAt = insurance.cancellationRequestedAt ? 
                                            this.datePipe.transform(insurance.cancellationRequestedAt) : 
                                            null;

    const formattedPrice = this.currencyPipe.transform(insurance.price);

    const priceTooltip = 'plan.insuranceAmount';
    const priceTooltipParams = { insuranceAmount: formattedPrice };

    if (insurance.cancelledAt) {
      return {
        tooltip: 'credits.cancelledInsurancePolicyDate',
        tooltipParams: { cancellationDate: formattedCancelledAt },
        priceTooltip,
        priceTooltipParams,
        icon: 'verified_user',
        color: 'red',
        needsCertificate: false
      };
    }

    if (insurance.cancellationRequestedAt) {
      return {
        tooltip: 'credits.cancellationRequestedInsurancePolicyDate',
        tooltipParams: { cancellationDate: formattedCancellationRequestedAt },
        priceTooltip,
        priceTooltipParams,
        icon: 'verified_user',
        color: 'gray',
        needsCertificate: false
      };
    }

    if (needsCertificate) {
      return {
        tooltip: 'credits.noInsuranceCertificate',
        priceTooltip,
        priceTooltipParams,
        icon: 'gpp_maybe',
        color: 'orange',
        needsCertificate: true
      };
    }

    return {
      tooltip: '',
      priceTooltip,
      priceTooltipParams,
      icon: 'verified_user',
      color: 'green',
      needsCertificate: false
    };
  }
}