import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ClientService } from '@app/core/services/client.service';
import { CollectionService } from '@app/core/services/collection.service';
import { CreditService } from '@app/core/services/credit.service';
import { EventLogService } from '@app/core/services/event-log.service';
import { IdentityReportsService } from '@app/core/services/identity-reports.service';
import { Client } from '@app/core/types/client';
import { Credit } from '@app/core/types/credit';
import { DateFormat } from '@app/core/types/date-format';
import {
  EventLogAudit,
  EventLogAuditDiff,
  EventLogAuditDisplay,
  EventLogAuditOperationType,
  EventLogAuditType,
  filterGroups,
} from '@app/core/types/event-log.type';
import { EventLogFilteringService } from '@app/main/main-layout/right-drawer/event-log/filtering/event-log-filtering.service';
import { AdministratorEmailToDisplayTextPipe } from '@app/main/client/pipes/administrator-email-to-display-text.pipe';
import { EditAuditDisplayTextPipe } from '@app/main/client/pipes/edit-audit-display-text.pipe';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  of,
  takeUntil,
  timeout,
  concatMap
} from 'rxjs';
import { PbxEvents } from '@app/main/socket-io/types/pbx-event.enum';
import { Socket } from '@app/main/socket-io/socket-io.service';
import {
  EventLogMode,
  EventLogHistoryType,
  EventLogSubheader,
} from '../shared/event-log.enums';
import { EventLogStateService } from './event-log-state.service';
import { EventLogState } from '../shared/state.interfaces';
import { EditNoteDialogComponent } from './notes/edit-note-dialog/edit-note-dialog.component';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { NoteService } from '@app/core/services/note.service';
import { AdministratorService } from '@app/core/services/administrator.service';
import { Administrator } from '@app/core/types/administrator';
import { CallService } from '@app/core/services/call.service';
import {
  Call,
  CallDirection,
  CallStatus,
  FORMATTED_HIDDEN_PHONE_NUMBER,
  FORMATTED_INQUIRY_PHONE_NUMBER,
  HIDDEN_PHONE_NUMBER,
  INQUIRY_PHONE_NUMBER,
} from '@app/core/types/call';
import { UserDetails } from '@app/core/types/user-details';
import { SessionService } from '@app/core/services/session.service';
import { SearchOptions } from '@app/core/types/search-options';
import { Note } from '@app/core/types/note';
import { FormatTopicsPipe } from '@app/shared/pipes/format-topics.pipe';
import { ContactPhone, ContactPhoneType } from '@app/core/types/contact-phone';
import { IsNoteEditablePipe } from '@app/shared/pipes/is-note-editable.pipe';
import { CallSummaryComponent } from '@app/main/client-communications/call-summary/call-summary.component';
import { FormatDurationPipe } from '@app/shared/pipes/duration.pipe';
import { EventLogFilterTemplate } from './filtering/event-log-filter-template';
import { merge } from 'lodash';
import { Utils } from '@app/core/utils/utils';

@Component({
  selector: 'itfg-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss'],
})
export class EventLogComponent implements OnInit, OnDestroy {
  EventLogSubheader = EventLogSubheader;

  mode: EventLogMode;
  credit: Credit;
  client: Client;
  call: Call;
  contacts: ContactPhone[];
  phoneNumber: string;
  subheader: EventLogSubheader;
  historyType: EventLogHistoryType;
  historyTypes: typeof EventLogHistoryType = EventLogHistoryType;
  eventLogAuditTypes: typeof EventLogAuditType = EventLogAuditType;
  administrators: Administrator[];
  currentlyLoggedInOperator: UserDetails;
  prevHistoryType: string | null = null;
  prevPhoneNumber: string | null = null;
  prevClient: Client | null = null;
  prevCredit: Credit | null = null;
  phoneTypes: ContactPhoneType[];
  callStatuses: typeof CallStatus = CallStatus;
  hiddenNumber: string = HIDDEN_PHONE_NUMBER;
  inquiryPhoneNumber: string = INQUIRY_PHONE_NUMBER;
  formattedHiddenPhoneNumber: string = FORMATTED_HIDDEN_PHONE_NUMBER;
  formattedInquiryPhoneNumber: string = FORMATTED_INQUIRY_PHONE_NUMBER;
  pendingHighlight: any = null;

  isLoading: boolean = true;

  activeLogData: EventLogAudit[] = [];
  creditLogData: EventLogAudit[] = [];
  callLogData: EventLogAudit[] = [];

  translatedCallLogData: EventLogAuditDisplay[];
  filteredCallLogData: EventLogAuditDisplay[];

  translatedCreditLogData: EventLogAuditDisplay[];
  filteredCreditLogData: EventLogAuditDisplay[];

  filterLogCtrl: UntypedFormControl = new UntypedFormControl('');
  displayedColumns: string[] = ['time', 'editor', 'creditId'];
  expandedElement: EventLogAudit | null;

  _unsubscribe: Subject<void> = new Subject<void>();
  dateFormatType: DateFormat;

  dateFormat: string = DateFormat.DATE;
  timeFormat: string = DateFormat.TIME;
  dateTimeFormat: string = DateFormat.DATE_TIME;
  searchExactMatch = false;

  filterAndPrepareData$ = new Subject<any>();

  logIssues: string[] = [];
  public uniqueSubProfiles: any[] = [];

  eventLogBatchSize = 200;
  eventLogCurrentBatch = 1;

  get activeFilteredLogData(): EventLogAuditDisplay[] {
    if (this.isLoading) {
      return undefined
    }

    if (this.historyType === EventLogHistoryType.CREDIT && this.filteredCreditLogData) {
      return this.filteredCreditLogData.slice(0, this.eventLogCurrentBatch * this.eventLogBatchSize)
    }

    if (this.historyType === EventLogHistoryType.PHONE && this.filteredCallLogData) {
      return this.filteredCallLogData.slice(0, this.eventLogCurrentBatch * this.eventLogBatchSize)
    }
  }

  constructor(
    private clientService: ClientService,
    private translateService: TranslateService,
    private editAuditDisplayText: EditAuditDisplayTextPipe,
    private administratorEmailToDisplayText: AdministratorEmailToDisplayTextPipe,
    private eventLogService: EventLogService,
    private collectionService: CollectionService,
    private reportService: IdentityReportsService,
    private creditService: CreditService,
    private templateService: EventLogFilteringService,
    private socketService: Socket,
    private eventLogStateService: EventLogStateService,
    private dialog: DialogProviderService,
    private noteService: NoteService,
    private administratorService: AdministratorService,
    private callsService: CallService,
    private session: SessionService,
    private formatTopicsPipe: FormatTopicsPipe,
    private isNoteEditablePipe: IsNoteEditablePipe,
    private formatDurationPipe: FormatDurationPipe,
  ) {
    const searchOptions = new SearchOptions();
    searchOptions.pageSize = 1000;

    this.dateFormatType = DateFormat;
    this.currentlyLoggedInOperator = this.session.currentlyLoggedOperator;
  }

  ngOnInit() {
    this.filterAndPrepareData$.pipe(
      debounceTime(300),
    ).subscribe(res => {
      this.filterAndPrepareData(res);
    });

    this.administratorService.administrators$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((administrators: Administrator[]) => {
        this.administrators = administrators;
        //TODO: check for race conditions
        // this.filterAndPrepareData$.next('administrators$');
      });

    this.watchForFilterEventLogValueChanges();

    this.templateService.filterTemplateChanged$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.filterAndPrepareData$.next('filterTemplateChanged$');
      });

    this.eventLogService.searchInEventLog
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((term) => {
        this.filterLogCtrl.patchValue(term);
      });

    this.socketService
      .fromEvent<{ callLog: any[]; phoneNumber: string }>(PbxEvents.LOG_RES)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(({ callLog, phoneNumber }) => {
        if (phoneNumber === this.phoneNumber) {
          this.callLogData = callLog.sort(
            (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
          );
          this.filterAndPrepareData$.next('PbxEvents.LOG_RES$');
        }
      });

    this.socketService
      .fromEvent<any>(PbxEvents.NOTE_ADDED)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((data: { call: Call; noteRecord: any }) => {
        if (
          this.historyType === EventLogHistoryType.PHONE &&
          this.phoneNumber === data.call.phoneNumber
        ) {
          this.callLogData.unshift(data.noteRecord);
          this.filterAndPrepareData$.next('PbxEvents.NOTE_ADDED$');
        }
      });

    this.noteService.onNoteChange.subscribe((success: boolean) => {
      if (success) {
        this.fetchEventLog();
      }
    });

    this.subscribeToCallRecordEvents();

    this.eventLogService.refreshEventLog.subscribe(() => this.fetchEventLog());

    this.eventLogStateService
      .getEventLogState()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((state: EventLogState) => {
        this.handleEventLogState(state);
      });

    this.eventLogService.highlightedEntity$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((highlightedEntity: Call | Credit | Client | null) => {
        if (!highlightedEntity) {
          // this.filterAndPrepareData$.next('highlightedEntity$$');
        } else if (highlightedEntity && highlightedEntity.hasOwnProperty('id'))
          this.highlightEntityInEventLog(highlightedEntity);
      });
  }

  showMore() {
    this.eventLogCurrentBatch += 1;
  }

  handleEventLogState(state: EventLogState) {
    const {
      mode,
      subheader,
      historyType,
      openedCredit,
      client,
      call,
      phoneNumber,
    } = state;

    const hasHistoryTypeChanged: boolean = this.prevHistoryType !== historyType;
    const hasCreditChanged: boolean = this.prevCredit?.id !== openedCredit?.id;
    const hasClientChanged: boolean = this.prevClient?.id !== client?.id;
    const hasPhoneNumberChanged: boolean =
      this.prevPhoneNumber !== phoneNumber &&
      historyType === EventLogHistoryType.PHONE;

    this.mode = mode;
    this.subheader = subheader;
    this.historyType = historyType;
    this.credit = openedCredit;
    this.client = client;
    this.call = call;
    this.phoneNumber = phoneNumber;

    const shouldLeavePhoneRoom: boolean =
      this.prevPhoneNumber &&
      this.prevPhoneNumber !== this.hiddenNumber &&
      this.prevPhoneNumber !== this.formattedHiddenPhoneNumber &&
      this.phoneNumber &&
      this.inquiryPhoneNumber &&
      this.phoneNumber !== this.formattedInquiryPhoneNumber;
    const shouldJoinPhoneRoom: boolean =
      this.phoneNumber &&
      this.phoneNumber !== this.hiddenNumber &&
      this.phoneNumber &&
      this.formattedHiddenPhoneNumber &&
      this.phoneNumber &&
      this.inquiryPhoneNumber &&
      this.phoneNumber !== this.formattedInquiryPhoneNumber;

    if (shouldLeavePhoneRoom) {
      this.socketService.leavePhoneNumberRoom(this.prevPhoneNumber);
    }

    if (shouldJoinPhoneRoom) {
      this.socketService.joinPhoneNumberRoom(this.phoneNumber);
    }

    if (
      hasHistoryTypeChanged ||
      hasCreditChanged ||
      hasClientChanged ||
      hasPhoneNumberChanged
    ) {
      this.fetchEventLog();
    }

    this.prevHistoryType = historyType;
    this.prevCredit = openedCredit;
    this.prevClient = client;
    this.prevPhoneNumber = phoneNumber;
  }

  toggleSubheader(subheader: EventLogSubheader) {
    this.eventLogStateService.setState({
      subheader: this.subheader === subheader ? null : subheader,
      historyType:
        subheader === EventLogSubheader.NOTES &&
          this.historyType === EventLogHistoryType.PHONE
          ? EventLogHistoryType.CREDIT
          : this.historyType,
    });
  }

  fetchEventLog() {
    this.isLoading = true;
    if (!this.credit) {
      this.creditLogData = [];
      this.filteredCreditLogData = [];
    }
    if (!this.phoneNumber) {
      this.callLogData = [];
      this.filteredCallLogData = [];
    }
    if (
      this.historyType === EventLogHistoryType.CREDIT &&
      this.client &&
      this.credit
    ) {
      this.getCreditLog(this.client, this.credit);
    }
    if (this.historyType === EventLogHistoryType.PHONE && this.phoneNumber) {
      if (
        this.phoneNumber === this.hiddenNumber ||
        this.phoneNumber === this.inquiryPhoneNumber
      ) {
        this.callLogData = [];
        this.translatedCallLogData = [];
        this.filteredCallLogData = [];
        this.filterAndPrepareData$.next('fetchEventLog');
      } else {
        this.isLoading = true;
        this.socketService.emitNewCallLogRequest(this.phoneNumber);
      }
    }
  }

  async filterAndPrepareBatch(activeLogData) {
    const hiddenTypes: string[] =
      this.templateService.filterTemplate.subTemplates
        .filter((t: EventLogFilterTemplate) => !t.shown)
        .map((t: EventLogFilterTemplate) => t.name) || [];

    const filteredByTemplate =
      activeLogData?.filter((d) => !hiddenTypes.includes(d?.type)) || [];

    const newTranslatedData = this.mapTranslations(filteredByTemplate) || [];
    const newFilteredData = this.filterEventLogEntries(
      newTranslatedData,
      this.filterLogCtrl.value
    );

    return { newTranslatedData, newFilteredData }
  }

  async filterAndPrepareData(caller: string) {
    console.log(`${caller} - Filter and prepare data`)
    let activeLogData: EventLogAudit[] = [];
    let translatedLogData: EventLogAuditDisplay[] = [];
    let filteredLogData: EventLogAuditDisplay[] = [];

    if (this.historyType === EventLogHistoryType.CREDIT) {
      activeLogData = this.creditLogData;
      translatedLogData = this.translatedCreditLogData;
      filteredLogData = this.filteredCreditLogData;
    } else if (this.historyType === EventLogHistoryType.PHONE) {
      activeLogData = this.callLogData;
      translatedLogData = this.translatedCallLogData;
      filteredLogData = this.filteredCallLogData;
    }

    const fullTranslatedData = [];
    const fullFilteredData = [];
    of(...Utils.chunkify(activeLogData, 100))
      .pipe(
        concatMap(entries => this.filterAndPrepareBatch(entries))
      )
      .subscribe(filteredChunk => {
        fullTranslatedData.push(...filteredChunk.newTranslatedData);
        fullFilteredData.push(...filteredChunk.newFilteredData);
      })
      .add(async () => {
        if (this.historyType === EventLogHistoryType.CREDIT) {
          this.translatedCreditLogData = fullTranslatedData;
          this.filteredCreditLogData = fullFilteredData;
        } else if (this.historyType === EventLogHistoryType.PHONE) {
          this.translatedCallLogData = fullTranslatedData;
          this.filteredCallLogData = fullFilteredData;
        }

        const highlightedEntity = this.eventLogService.getHighlightedEntityInEventLog();
        if (highlightedEntity) {
          this.highlightEntityInEventLog(highlightedEntity);
        }
        this.isLoading = false;
      })
  }

  filterEventLogEntries(data: any, searchStr: string) {
    searchStr = searchStr && searchStr.toLowerCase();
    return data
      .filter((audit: EventLogAuditDisplay) => {
        const value = Object.values(audit.displayText).join('\r\n');
        const escapedValue = this.stripHtmlTags(value);
        let match: boolean = escapedValue.toLowerCase().includes(searchStr);
        const searchValues = searchStr ? searchStr.split(/\s/) : [];
        const matches = [];

        if (!this.searchExactMatch) {
          searchValues.forEach((s: string) => {
            if (escapedValue.toLowerCase().includes(s)) {
              matches.push(s);
            }
          });

          match = searchValues.length === matches.length;
        }
        return match;
      })
      .map((audit: EventLogAuditDisplay) => {
        const highlight = (value: string) =>
          this.eventLogService.highlight({
            value: value,
            searchStr,
            exactMatch: this.searchExactMatch,
          });

        Object.keys(audit.displayText).forEach((key) => {
          audit.displayText[key] = highlight(audit.html[key]);
        });

        return audit;
      });
  }

  watchForFilterEventLogValueChanges() {
    this.filterLogCtrl.valueChanges
      .pipe(
        filter((searchStr: string) => !!searchStr && searchStr.length > 0),
        debounceTime(150),
        takeUntil(this._unsubscribe)
      )
      .subscribe((searchStr: string) => {
        if (this.historyType === EventLogHistoryType.PHONE) {
          this.filteredCallLogData = this.filterEventLogEntries(
            this.translatedCallLogData,
            searchStr
          );
        } else if (this.historyType === EventLogHistoryType.CREDIT) {
          this.filteredCreditLogData = this.filterEventLogEntries(
            this.translatedCreditLogData,
            searchStr
          );
        } else {
          this.filteredCallLogData = [];
          this.filteredCreditLogData = [];
        }
      });

    this.filterLogCtrl.valueChanges
      .pipe(
        filter((searchStr: string) => !searchStr || searchStr.length === 0),
        takeUntil(this._unsubscribe)
      )
      .subscribe((searchStr: string) => {
        if (this.historyType === EventLogHistoryType.PHONE) {
          this.filteredCallLogData = this.filterEventLogEntries(
            this.translatedCallLogData,
            searchStr
          );
        } else if (this.historyType === EventLogHistoryType.CREDIT) {
          this.filteredCreditLogData = this.filterEventLogEntries(
            this.translatedCreditLogData,
            searchStr
          );
        } else {
          this.filteredCallLogData = [];
          this.filteredCreditLogData = [];
        }
      });
  }

  getCreditLog(user: Client, credit?: Credit) {
    this.isLoading = true;
    forkJoin([...this.getCreditLogObservables$(user, credit)]).subscribe(
      (res: any) => {
        const data = res.flat();
        data.sort((a, b) => (new Date(a.time) < new Date(b.time) ? 1 : -1));
        this.creditLogData = data;
        this.filterAndPrepareData$.next('getCreditLog');
      }
    );
  }

  mapTranslations(data: EventLogAudit[]) {
    const translatedAuditList = data.map((audit: EventLogAudit) => ({
      type: audit?.type,
      time: {
        formatted: this.eventLogService.translationMap.time(audit.time),
        raw: audit.time,
      },
      operation: audit.operation,
      editor: this.eventLogService.translationMap.editor(
        audit.editor,
        this.client?.id
      ),
      data: {
        diff:
          audit.data.diff &&
          audit.data.diff.map((diff: EventLogAuditDiff) => {
            return this.editAuditDisplayText.transform(diff, null, audit);
          }),
        entity: this.mapEntityFields(audit),
      },
      creditId: audit.data.entity.creditId || null,
      editableEntityData: audit.data.entity || null,
    }));

    translatedAuditList.map((audit: EventLogAuditDisplay) => {
      audit.displayText = {
        editor:
          audit.type === EventLogAuditType.CALL ||
            audit.type === EventLogAuditType.NOTE
            ? this.getOperatorEmail(audit.editor)
            : this.administratorEmailToDisplayText.transform(audit.editor),
        operation: audit.operation,
        type: audit.type,
        time: audit.time.formatted,
        description: '',
        creditId: audit.creditId ? audit.creditId : '',
      };

      if (audit.type === EventLogAuditType.COLLECTION) {
        audit.displayText.description = audit.editableEntityData?.collectorName;

        if (audit.editableEntityData.instanceName) {
          audit.displayText.description = `${audit.editableEntityData.instanceName} / ${audit.editableEntityData.collectorName}`;
        }

        if (audit.editableEntityData.agentType) {
          const translatedType = this.translateService.instant(
            'collection.agentType.' + audit.editableEntityData.agentType
          );
          audit.displayText.description = `${translatedType} - ${audit.displayText.description}`;
        }

        if (audit.operation === EventLogAuditOperationType.RETURN) {
          audit.displayText.description = this.strikethrough(
            audit.displayText.description
          );
        }
      } else if (audit.type === EventLogAuditType.CALL) {
        const translatedNote = this.translateService.instant('communication.note');
        const translatedTopic = this.translateService.instant('clientComunications.topics.label');

        let descriptionParts = [];
        const direction = audit.editableEntityData.direction === CallDirection.OUT ? `${audit.data.entity.direction.value.substring(0, 3)}.` : `${audit.data.entity.direction.value.substring(0, 2)}.`
        descriptionParts.push(direction);

        if (audit.editableEntityData.phoneNumber && this.historyType === EventLogHistoryType.CREDIT) {
          const phoneDisplay = audit.editableEntityData.phoneNumber === this.hiddenNumber
            ? this.translateService.instant('communication.hiddenNumber')
            : audit.editableEntityData.phoneNumber;
          const formattedPhoneDisplay = this.eventLogStateService.getBulgarianPhoneNumberWithCountryCode(phoneDisplay);
          descriptionParts.push(`,&nbsp;${formattedPhoneDisplay}`);
        }

        if (audit.editableEntityData.duration && audit.editableEntityData.waitingTime && (audit.editableEntityData.status === CallStatus.ANSWER || audit.editableEntityData.status === CallStatus.AGENT_CONNECTED)) {
          const duration: number = audit.editableEntityData.duration;
          const waitingTime: number = audit.editableEntityData.waitingTime;
          const talkingTime: number = duration - waitingTime;

          if (talkingTime > 0) {
            descriptionParts.push(`&nbsp;(${this.formatDurationPipe.transform(talkingTime)})`);
          }
        }

        let mainDescription = descriptionParts.join('');

        if (audit.data.entity.status.value) {
          mainDescription += `,&nbsp;${audit.data.entity.status.value}`;
        }

        let noteDescriptions = '';
        if (Array.isArray(audit.editableEntityData.notes) && audit.editableEntityData.notes.length > 0) {
          noteDescriptions = audit.editableEntityData.notes
            .map((note: Note) => {
              let noteDescription: string = '';

              if (note.content) {
                noteDescription += `${this.div(`${translatedNote}: ${note.content}`)}`;
              }

              if (Array.isArray(note.topics) && note.topics.length > 0) {
                const formattedTopics = this.formatTopicsPipe.transform(note.topics);
                const markedTopics = formattedTopics
                  .split(',&nbsp;')
                  .map((topic: string) => this.italic(topic));
                noteDescription += this.div(
                  `${translatedTopic}:&nbsp;${markedTopics.join(',&nbsp;')}`
                );
              }

              return noteDescription;
            })
            .reduce((acc, curr, index, array) => {
              if (curr) {
                acc += curr;
                if (index !== array.length - 1) {
                  acc += '<hr/>';
                }
              }
              return acc;
            }, '');

          const creditId = this.getCreditIdFromNotes(audit.editableEntityData.notes);
          if (creditId) {
            audit.displayText.creditId = creditId;
          }
        }

        audit.displayText.description = mainDescription + (noteDescriptions ? '<br/>' + noteDescriptions : '');
      } else if (audit.type === EventLogAuditType.NOTE) {
        let descriptionParts: string[] = [];

        if (audit.data.entity.content.value) {
          descriptionParts.push(audit.data.entity.content.value);
        }

        if (
          Array.isArray(audit.editableEntityData.topics) &&
          audit.editableEntityData.topics.length > 0
        ) {
          const topics = audit.editableEntityData.topics;
          const formattedTopics = this.formatTopicsPipe.transform(topics);

          const markedTopics = formattedTopics
            .split(',&nbsp;')
            .map((topic: string) => this.italic(topic));

          descriptionParts.push(this.div(`${markedTopics.join(',&nbsp')}`));
        }

        audit.displayText.description = descriptionParts.join(',&nbsp;');
      } else if (audit.data.diff) {
        audit.displayText.description = audit.data.diff
          .map((auditDiff: EventLogAuditDiff) => {
            const description = this.div(
              `${this.italic(auditDiff.field)}:&nbsp${auditDiff.oldValue
              }&nbsp→&nbsp${auditDiff.newValue}`
            );
            return description;
          })
          .join('\r\n');
      } else {
        audit.displayText.description = Object.keys(audit.data.entity)
          .filter((key: string) => {
            return (
              audit.data.entity[key].value !== null &&
              audit.data.entity[key].value !== undefined &&
              audit.data.entity[key].value !== ''
            );
          })
          .map((key: string) => {
            const value = audit.data.entity[key];

            const description = !!value.field
              ? `${value.field}:&nbsp${value.value}`
              : value.value;
            return description;
          })
          .join(', ');
      }

      audit.displayText.type = this.eventLogService.translationMap.type(
        audit.type
      );
      audit.displayText.operation =
        this.eventLogService.translationMap.operation(audit.operation);
      audit.html = JSON.parse(JSON.stringify(audit.displayText));
      return audit;
    });

    return translatedAuditList;
  }

  getCreditLogObservables$(user: Client, credit?: Credit) {
    const observablesArr: Array<Observable<any>> = [of([])];
    const showCreditLog = !this.eventLogService.clientLogToggled;
    const getObservable = <T>(observable: Observable<T>, logIssue: string) => {
      return observable.pipe(
        timeout(5000), // 5 seconds
        catchError((error) => {
          console.error(
            `Failed to fetch ${logIssue.toLowerCase()} log.`,
            error
          );
          this.logIssues = [...this.logIssues, logIssue];
          return of([]);
        })
      );
    };

    if (this.filterGroupShown(filterGroups.IDENTITY_REPORTS) && user.civilId) {
      const reportsLog$ = getObservable(
        this.reportService.getEventLog(user.civilId),
        filterGroups.IDENTITY_REPORTS
      );

      observablesArr.push(reportsLog$);
    }

    if (this.filterGroupShown(filterGroups.SCORE)) {
      if (showCreditLog) {
        const scoreEventLogByCredit$ = getObservable(
          this.creditService.getScoreEventLogByCredit$(credit?.id),
          filterGroups.SCORE
        );

        observablesArr.push(scoreEventLogByCredit$);
      } else {
        const scoreEventLogByUser$ = getObservable(
          this.creditService.getScoreEventLogByCivilId$(user?.civilId),
          filterGroups.SCORE
        );

        observablesArr.push(scoreEventLogByUser$);
      }
    }

    if (this.filterGroupShown(filterGroups.COLLECTION)) {
      const getAssignmentHistoryLog$ = getObservable(
        this.collectionService.getAssignmentHistoryLog$(
          this.credit.id,
          this.eventLogService.clientLogToggled ? user && user.id : undefined
        ),
        filterGroups.COLLECTION
      );

      observablesArr.push(getAssignmentHistoryLog$);
    }

    if (this.filterGroupShown(filterGroups.CREDITS)) {
      const creditsLog$ = getObservable(
        this.clientService.getEventLog$(
          user.id,
          showCreditLog ? credit.id : null
        ),
        filterGroups.CREDITS
      );

      observablesArr.push(creditsLog$);
    }

    if (this.filterGroupShown(filterGroups.CLIENT_COMMUNICATION)) {
      if (showCreditLog) {
        const conversationLogByCreditId$ = getObservable(
          this.callsService.getConversationLogByCreditId(
            (credit && credit.id) || this.credit.id
          ),
          filterGroups.CLIENT_COMMUNICATION
        );

        observablesArr.push(conversationLogByCreditId$);
      } else {
        const conversationLogByClientId$ = getObservable(
          this.callsService.getConversationLogByClientId(
            (user && user.id) || this.client.id
          ),
          filterGroups.CLIENT_COMMUNICATION
        );

        observablesArr.push(conversationLogByClientId$);
      }
    }

    return observablesArr;
  }

  filterGroupShown(filterGroupName: string): boolean {
    const res = this.templateService.filterTemplate.subTemplates.find(
      (t: EventLogFilterTemplate) => t.group === filterGroupName && t.shown
    );
    return !!res;
  }

  mapEntityFields(audit: EventLogAudit) {
    const entity = audit.data.entity;
    const mappedEntity = {};
    const auditType = audit.type;
    const configMap = this.eventLogService.ENTITY_FIELD_MAP[auditType];

    for (const field in configMap) {
      if (configMap.hasOwnProperty(field) && entity.hasOwnProperty(field)) {
        const auditConfig = this.eventLogService.getAuditEntityConfig(
          auditType,
          field
        );
        const value = entity[field];

        if (!auditConfig) {
          continue;
        }

        const { label, format } = auditConfig;
        mappedEntity[field] = {
          field: label ? this.translateService.instant(label) : null,
          value: format ? format(value) : value,
        };

        mappedEntity[field].field = mappedEntity[field].field
          ? this.italic(mappedEntity[field].field)
          : mappedEntity[field].field;
      }
    }

    return mappedEntity;
  }

  getOperatorEmail(editor: string | number): any {
    const operator = this.findMatchingOperator(editor);

    if (operator && editor) {
      const operatorEmail = this.administratorEmailToDisplayText.transform(
        operator.email
      );

      return operatorEmail;
    } else if (!operator && editor && !editor.toString().startsWith('КП')) {
      return (
        this.translateService.instant('global.operator') + ' №:' + ' ' + editor
      );
    }

    return this.translateService.instant('callLog.client');
  }

  findMatchingOperator(editor: string | number): Administrator | null {
    return this.administrators.find(
      (administrator: Administrator) => administrator.id === Number(editor)
    );
  }

  openEditNoteDialog(auditEditableData: Note | Note[]) {
    if (!Array.isArray(auditEditableData)) {
      auditEditableData = [auditEditableData];
    }

    const editableNotes = auditEditableData.filter((note: Note) =>
      this.isNoteEditablePipe.transform(note)
    );

    return this.dialog
      .open(EditNoteDialogComponent, {
        data: {
          notes: editableNotes,
        },
        restoreFocus: false,
        minWidth: '600px',
      })
      .afterClosed()
      .pipe(filter((success) => !!success))
      .subscribe(() => {
        this.noteService.onNoteChange.next(true);
      });
  }

  subscribeToCallRecordEvents() {
    this.socketService
      .fromEvent<EventLogAudit>(PbxEvents.CALL_RECORD_CREATE)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((newCallRecord: EventLogAudit) => {
        if (this.historyType === EventLogHistoryType.PHONE) {
          this.callLogData.unshift(newCallRecord);
          this.filterAndPrepareData$.next('subscribeToCallRecordEvents');
        }
      });

    this.socketService
      .fromEvent<EventLogAudit>(PbxEvents.CALL_RECORD_UPDATE)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((updatedRecord: EventLogAudit) => {
        this.handleCallRecordUpdate(updatedRecord);
      });
  }

  handleCallRecordUpdate(record: EventLogAudit) {
    let logData = this.historyType === EventLogHistoryType.CREDIT ? this.creditLogData : this.callLogData;
    const index = logData.findIndex((logRecord: EventLogAudit) => logRecord.data.entity.id === record.data.entity.id);

    if (index !== -1) {
      logData[index] = record;
      logData = [...logData];
      this.filterAndPrepareData$.next('handleCallRecordUpdate');
    };
  }

  highlightEntityInEventLog(entity: Call | Credit | Client | null) {
    let filteredLogData = this.historyType === EventLogHistoryType.CREDIT
      ? this.filteredCreditLogData
      : this.filteredCallLogData;

    const isAlreadyHighlighted: boolean = filteredLogData?.some(
      (logRecord: EventLogAuditDisplay) => logRecord.editableEntityData.id === entity.id && logRecord.highlight
    );

    if (isAlreadyHighlighted) {
      return;
    }

    this.eventLogService.setHighlightedEntityInEventLog(entity);

    if (!filteredLogData || filteredLogData.length === 0) {
      return;
    }

    filteredLogData = filteredLogData.map((logRecord: EventLogAuditDisplay) => ({
      ...logRecord,
      highlight: false
    }));

    const index = filteredLogData.findIndex((logRecord: EventLogAuditDisplay) => logRecord.editableEntityData.id === entity.id);

    if (index !== -1) {
      filteredLogData[index].highlight = true;
    }

    if (this.historyType === EventLogHistoryType.CREDIT) {
      this.filteredCreditLogData = [...filteredLogData];
    } else {
      this.filteredCallLogData = [...filteredLogData];
    }
  }

  viewCallRecord(call: Call) {
    return window.open(call.record, '_blank');
  }

  viewCallDetails(call: Call) {
    this.dialog.open(CallSummaryComponent, {
      minWidth: '600px',
      maxWidth: '800px',
      maxHeight: '90vh',
      autoFocus: false,
      restoreFocus: false,
      data: {
        call: call,
        administrators: this.administrators,
      }
    });
  }

  stripHtmlTags(text: string): string {
    return text && text.toString().replace(/(<([^>]+)>)/gi, '');
  }

  div(value: string) {
    return this.eventLogService.div(value);
  }

  italic(value: string) {
    return this.eventLogService.italic(value);
  }

  strikethrough(value: string) {
    return this.eventLogService.strikethrough(value);
  }

  clearFormControl(ctrl: UntypedFormControl) {
    ctrl.reset();
  }

  getCreditIdFromNotes(notes: Note[]): number | string {
    for (const note of notes) {
      if (note.creditId) {
        return note.creditId;
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.phoneNumber) {
      this.socketService.leavePhoneNumberRoom(this.phoneNumber);
    }
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
