import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { HighlightFirstAutocompleteOptionOnChangeDirective } from './directives/highlight-first-autocomplete-option-on-change.directive';
import { SelectFirstAutocompleteOptionOnBlurDirective } from './directives/select-first-autocomplete-option-on-blur.directive';
import { CityDisplayTextPipe } from './pipes/city-display-text.pipe';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import { AbstractControlToFormArrayPipe } from './pipes/abstract-control-to-form-array.pipe';
import { AbstractControlToFormGroupPipe } from './pipes/abstract-control-to-form-group.pipe';
import { BooleanTranslationPipe } from './pipes/boolean-translation.pipe';
import { StickyTabsDirective } from './directives/sticky-tabs.directive';
import { AdministratorFullNamePipe } from './pipes/administrator-full-name.pipe';
import { ObjectDiffPipe } from './pipes/object-diff.pipe';
import { HistoryDiffPipe } from './pipes/history-diff.pipe';
import { TransactionStatusTranslationPipe } from './pipes/transaction-status-transation.pipe';
import { TransactionTypeTranslationPipe } from './pipes/transaction-type-transation.pipe';
import { ObjectDeepDiffPipe } from './pipes/object-deep-diff.pipe';
import { PaymentAccountsDisplayPipe } from './pipes/payment-accounts-display.pipe';
import { IsTruncated } from './pipes/get-last-chars.pipe';
import { PermissionsDirective } from './directives/permissions.directive';
import { IsUnauthorizedPipe } from './pipes/is-unauthorized.pipe';
import { ScrollElementIntoViewDirective } from './directives/scroll-element-into-view.directive';
import { ItfgDateFormatPipe } from './pipes/itfg-date-format.pipe';
import { DateFormatComponent } from './components/date-format/date-format.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PhonePrefixDirective } from './directives/phone-prefix.directive';
import { ModalPositionCache } from './cache/modal-position.cache';
import { BrandLogoComponent } from './components/brand-logo/brand-logo.component';
import { ScrollIntoViewOnOpenDirective } from './directives/scroll-into-view-on-open.directive';
import { FormControlClearComponent } from './components/form-control-clear/form-control-clear.component';
import { HighlightDataTableRowDirective } from './directives/highlight-data-table-row.directive';
import { BondsterMetaVisualizationDirective } from './directives/bondster-meta-visualization.directive';
import { StickyElementDirective } from './directives/sticky-element.directive';
import { EditAuditDisplayTextPipe } from '../main/client/pipes/edit-audit-display-text.pipe';
import { TrimInputDirective } from './directives/trim-input.directive';
import { MatDialogRestorePositionDirective } from './directives/mat-dialog-restore-position.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ZoomImageDirective } from './directives/zoom-image.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click';
import { MatBadgeIconDirective } from './directives/mat-badge-icon.directive';
import { LazyLoadTabContentDirective } from './directives/lazy-load-tab.directive';
import { UtilizationFeeNamePipe } from './pipes/utilization-fee-name.pipe';
import { RouterModule } from '@angular/router';
import { SearchInEventLogButtonComponent } from './components/search-in-event-log-button/search-in-event-log-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipOnOverflowDirective } from './directives/tooltip-on-overflow.directive';
import { MaterialModule } from '../lib/material.module';
import { MatTabMenuComponent } from './components/mat-tab-menu/mat-tab-menu.component';
import { TogglePasswordVisibilityDirective } from './directives/toggle-password-visibility.directive';
import { MessageComponent } from './components/message/message.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CountryCodePipe } from './pipes/country-code.pipe';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { CallStatusTranslationPipe } from './pipes/call-status-translation.pipe';
import { FormatDurationPipe } from './pipes/duration.pipe';
import { AdministratorExtensionToNamePipe } from './pipes/administrator-extension-to-name.pipe';
import { HasCallRecordAccessPipe } from './pipes/has-call-record-access.pipe';
import { FormatTopicName } from './pipes/topic-separator-visualization.pipe';
import { FormatTopicsPipe } from './pipes/format-topics.pipe';
import { GetTopicRootPipe } from './pipes/get-topic-root.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ClickToCallButtonComponent } from './components/click-to-call-button/click-to-call-button.component';
import { DetectMouseClickDirective } from './directives/detect-mouse-click-type.directive';
import { IsNoteEditablePipe } from './pipes/is-note-editable.pipe';
import { FormatToBulgarianPhoneNumberPipe } from './pipes/format-to-bulgarian-phone-number.pipe';
import { ParseUserAgentPipe } from './pipes/parse-user-agent.pipe';
import { HoverDropDownDirective } from './directives/hover-dropdown.directive';
import { UserDocumentIconPipe } from '@app/main/credit/pipes/user-document-icon.pipe';
import { ShortenFileNamePipe } from '@app/main/credit/pipes/shorten-filename.pipe';
import { InsuranceStatusPipe } from './pipes/insurance-status.pipe';

@NgModule({
  imports: [
    DataTableModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    AbstractControlToFormArrayPipe,
    AbstractControlToFormGroupPipe,
    BooleanTranslationPipe,
    CityDisplayTextPipe,
    HighlightFirstAutocompleteOptionOnChangeDirective,
    ObjectToArrayPipe,
    SelectFirstAutocompleteOptionOnBlurDirective,
    StickyTabsDirective,
    AdministratorFullNamePipe,
    ObjectDiffPipe,
    ObjectDeepDiffPipe,
    HistoryDiffPipe,
    TransactionStatusTranslationPipe,
    TransactionTypeTranslationPipe,
    PaymentAccountsDisplayPipe,
    IsTruncated,
    PermissionsDirective,
    IsUnauthorizedPipe,
    ScrollElementIntoViewDirective,
    ItfgDateFormatPipe,
    DateFormatComponent,
    PhonePrefixDirective,
    BrandLogoComponent,
    MatDialogRestorePositionDirective,
    ScrollIntoViewOnOpenDirective,
    FormControlClearComponent,
    HighlightDataTableRowDirective,
    BondsterMetaVisualizationDirective,
    StickyElementDirective,
    EditAuditDisplayTextPipe,
    TrimInputDirective,
    ZoomImageDirective,
    PreventDoubleClickDirective,
    MatBadgeIconDirective,
    TogglePasswordVisibilityDirective,
    TooltipOnOverflowDirective,
    LazyLoadTabContentDirective,
    UtilizationFeeNamePipe,
    SearchInEventLogButtonComponent,
    MatTabMenuComponent,
    MessageComponent,
    FileUploadComponent,
    LoadingIndicatorComponent,
    ClickToCallButtonComponent,
    CountryCodePipe,
    StopPropagationDirective,
    CallStatusTranslationPipe,
    FormatDurationPipe,
    AdministratorExtensionToNamePipe,
    HasCallRecordAccessPipe,
    FormatTopicsPipe,
    FormatTopicName,
    GetTopicRootPipe,
    SanitizeHtmlPipe,
    DetectMouseClickDirective,
    IsNoteEditablePipe,
    FormatToBulgarianPhoneNumberPipe,
    ParseUserAgentPipe,
    HoverDropDownDirective,
    UserDocumentIconPipe,
    ShortenFileNamePipe,
    InsuranceStatusPipe,
  ],
  exports: [
    AbstractControlToFormArrayPipe,
    AbstractControlToFormGroupPipe,
    BooleanTranslationPipe,
    CityDisplayTextPipe,
    HighlightFirstAutocompleteOptionOnChangeDirective,
    ObjectToArrayPipe,
    SelectFirstAutocompleteOptionOnBlurDirective,
    StickyTabsDirective,
    AdministratorFullNamePipe,
    ObjectDiffPipe,
    ObjectDeepDiffPipe,
    TransactionStatusTranslationPipe,
    TransactionTypeTranslationPipe,
    ItfgDateFormatPipe,
    PaymentAccountsDisplayPipe,
    IsTruncated,
    NgxPermissionsModule,
    PermissionsDirective,
    IsUnauthorizedPipe,
    ScrollElementIntoViewDirective,
    DateFormatComponent,
    PhonePrefixDirective,
    MatDialogRestorePositionDirective,
    BrandLogoComponent,
    ScrollIntoViewOnOpenDirective,
    FormControlClearComponent,
    HighlightDataTableRowDirective,
    BondsterMetaVisualizationDirective,
    StickyElementDirective,
    EditAuditDisplayTextPipe,
    TrimInputDirective,
    ZoomImageDirective,
    PreventDoubleClickDirective,
    MatBadgeIconDirective,
    TogglePasswordVisibilityDirective,
    TooltipOnOverflowDirective,
    LazyLoadTabContentDirective,
    UtilizationFeeNamePipe,
    SearchInEventLogButtonComponent,
    MatTabMenuComponent,
    MessageComponent,
    FileUploadComponent,
    ClickToCallButtonComponent,
    CountryCodePipe,
    StopPropagationDirective,
    CallStatusTranslationPipe,
    FormatDurationPipe,
    AdministratorExtensionToNamePipe,
    HasCallRecordAccessPipe,
    FormatTopicsPipe,
    FormatTopicName,
    GetTopicRootPipe,
    SanitizeHtmlPipe,
    DetectMouseClickDirective,
    IsNoteEditablePipe,
    FormatToBulgarianPhoneNumberPipe,
    ParseUserAgentPipe,
    HoverDropDownDirective,
    UserDocumentIconPipe,
    ShortenFileNamePipe,
    InsuranceStatusPipe,
  ],
  providers: [ModalPositionCache, UtilizationFeeNamePipe],
})
export class SharedModule {
  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [CityDisplayTextPipe],
    };
  }
}
