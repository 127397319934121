import { Installment } from './installment';
import { Credit } from './credit';
import { Administrator } from './administrator';

export enum AccrualCode {
  INTEREST = 'INTEREST',
  FORFEIT = 'FORFEIT',
  OVERDUE_PENALTY = 'PENALTY',
  UTILIZATION_FEE = 'UTILIZATION_FEE',
  COURT_FEE = 'COURT_FEE',
  CONSULTANT_FEE = 'CONSULTANT_FEE',
  EARLY_REPAYMENT_FEE = 'EARLY_REPAYMENT_FEE',
}

export interface AccrualType {
  id: number;
  code: AccrualCode;
}

export interface Accrual {
  id: number;
  type: AccrualType;
  amount: number;
  date: string;
  reference?: string;
  createdBy?: Administrator;
  installment?: Installment;
  credit?: Credit;
  note?: string;
  createdAt?: string;
}

export enum FormerTaxCodes {
  COURT_FEE = AccrualCode.COURT_FEE,
  CONSULTANT_FEE = AccrualCode.CONSULTANT_FEE,
  EARLY_REPAYMENT_FEE = AccrualCode.EARLY_REPAYMENT_FEE,
}

export enum AccrualKeys {
  INTEREST = 'interest',
  FORFEIT = 'forfeit',
  OVERDUE_PENALTY = 'penalty',
  UTILIZATION_FEE = 'utilizationFee',
  COURT_FEE = 'courtFee',
  CONSULTANT_FEE = 'consultantFee',
  EARLY_REPAYMENT_FEE = 'earlyRepaymentFee',
}

export enum AccrualKeysInstallment {
  INTEREST = 'interest',
  FORFEIT = 'forfeit',
  OVERDUE_PENALTY = 'penalty',
  UTILIZATION_FEE = 'utilizationFee',
}

export enum AccrualKeysExtended {
  PRINCIPAL = 'principal',
  INTEREST = 'interest',
  FORFEIT = 'forfeit',
  PENALTY = 'penalty',
  UTILIZATION_FEE = 'utilizationFee',
  COURT_FEE = 'courtFee',
  CONSULTANT_FEE = 'consultantFee',
  EARLY_REPAYMENT_FEE = 'earlyRepaymentFee',
  TOTAL = 'total',
  TOTAL_DEDUCTED = 'totalDeducted',
}

export type AccrualBuckets = {
  [key in AccrualKeys]: Accrual[];
};

export type InstallmentAccrualBuckets = {
  [key in AccrualKeysInstallment]: Accrual[];
};

export interface AccrualFlagsDto {
  interest?: boolean;
  forfeit?: boolean;
  penalty?: boolean;
  utilizationFee?: boolean;
}
