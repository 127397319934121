import { NgModule } from '@angular/core';
import { CreditStatusToTranslationKeyPipe } from './pipes/credit-status-to-translation-key.pipe';
import { ConversationTypesTranslationPipe } from './pipes/conversation-types-translation.pipe';
import { CreditStatusReasonToTranslationKeyPipe } from './pipes/credit-status-reason-to-translation-key.pipe';
import { InstallmentStatusToTranslationKeyPipe } from './pipes/installment-status-to-translation-key.pipe';
import { ToCustomItfgCurrencyPipe } from './pipes/to-custom-itfg-currency.pipe';
import { ClientRelationTypeToTranslationKeyPipe } from './pipes/client-relation-type-to-translation-key.pipe';
import { PaymentMethodToTranslationKeyPipe } from './pipes/payment-method-to-translation-key.pipe';
import { FormatIbanPipe } from './pipes/format-iban.pipe';
import { CreditInstalmentPeriodPipe } from './pipes/credit-instalment-period.pipe';
import {ClientIdVerificationTypeToTranslationKeyPipe} from './pipes/client-id-verification-type-to-translation-key.pipe';
import { ReminderOptionToDisplayTextPipe } from './pipes/reminder-option-to-display-text.pipe';
import { PaymentPromiseStatusToTranslationKeyPipe } from './pipes/payment-promise-status-to-translation-key.pipe';
import {PaymentAgreementStatusToTranslationKeyPipe} from './pipes/payment-agreement-status-to-translation-pipe';
import { PaymentPromiseStatusDescriptionTooltip } from './pipes/payment-promise-status-description-tooltip';
import { IsAccrualCorrectablePipe } from './pipes/is-accrual-correctable.pipe';
import { InsuranceCancellablePipe } from '@app/shared/pipes/is-insurance-cancellable.pipe';
import { ScoreMetadataNodeToDisplayTextPipe } from './pipes/score-metadata-node-to-display-text.pipe';

@NgModule({
  declarations: [
    CreditStatusToTranslationKeyPipe,
    ConversationTypesTranslationPipe,
    CreditStatusReasonToTranslationKeyPipe,
    InstallmentStatusToTranslationKeyPipe,
    ToCustomItfgCurrencyPipe,
    ClientRelationTypeToTranslationKeyPipe,
    PaymentMethodToTranslationKeyPipe,
    FormatIbanPipe,
    CreditInstalmentPeriodPipe,
    ClientIdVerificationTypeToTranslationKeyPipe,
    ReminderOptionToDisplayTextPipe,
    PaymentPromiseStatusToTranslationKeyPipe,
    PaymentAgreementStatusToTranslationKeyPipe,
    PaymentPromiseStatusDescriptionTooltip,
    IsAccrualCorrectablePipe,
    InsuranceCancellablePipe,
    ScoreMetadataNodeToDisplayTextPipe
  ],
  exports: [
    CreditStatusToTranslationKeyPipe,
    ConversationTypesTranslationPipe,
    CreditStatusReasonToTranslationKeyPipe,
    InstallmentStatusToTranslationKeyPipe,
    ToCustomItfgCurrencyPipe,
    ClientRelationTypeToTranslationKeyPipe,
    PaymentMethodToTranslationKeyPipe,
    FormatIbanPipe,
    CreditInstalmentPeriodPipe,
    ClientIdVerificationTypeToTranslationKeyPipe,
    ReminderOptionToDisplayTextPipe,
    PaymentPromiseStatusToTranslationKeyPipe,
    PaymentAgreementStatusToTranslationKeyPipe,
    PaymentPromiseStatusDescriptionTooltip,
    IsAccrualCorrectablePipe,
    InsuranceCancellablePipe,
    ScoreMetadataNodeToDisplayTextPipe
  ],
})
export class CreditPipesModule {}
