import { NgModule, APP_INITIALIZER, forwardRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainModule } from './main/main.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PermissionsService } from './core/services/permissions.service';
import { SocketIoModule } from './main/socket-io/socket-io.module';
import { SocketIoConfig } from './main/socket-io/socket-io.config';
import {
  Favicons,
  BrowserFavicons,
  BROWSER_FAVICONS_CONFIG,
} from './favicon/favicons';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlLocalizedService } from '@app/core/services/mat-paginator-intl-localized.service';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './core/services/loading.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@environments/environment';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  // subscriptSizing: 'dynamic'
};

const config: SocketIoConfig = {
  url: environment.CLIENT_COMMUNICATION_API_URL,
  options: {
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 10000, // defaults to 1000
    reconnectionDelayMax: 10000, // defaults to 5000
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MainModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SocketIoModule.forRoot(config),
    AuthModule,
    AppRoutingModule,
    TranslateModule,
  ],
  providers: [
    PermissionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: (permissions: PermissionsService) => () =>
        permissions.initialize(),
      deps: [PermissionsService],
      multi: true,
    },
    {
      provide: Favicons,
      useClass: BrowserFavicons,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          itfgGroup: {
            type: 'image/png',
            href: `../assets/images/favicon-flower-${environment.envName}.png`,
            isDefault: true,
          },
          notification: {
            type: 'image/png',
            href: '../assets/images/favicon-notification.png',
            isDefault: false,
          },
        },
        cacheBusting: true,
      },
    },
    {
      provide: MatPaginatorIntl,
      useClass: forwardRef(() => MatPaginatorIntlLocalizedService),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
