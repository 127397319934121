@if (!!faceVerification) {
  <div layout="row"
        layout-align="start center"
        class="verification-container"
        matTooltip="{{ 'reports.identityVerification.faceVerificationTooltip' | translate:{timestamp:(faceVerification.createdAt | itfgDateFormat), referenceId: faceVerification?.referenceId } }}"
        matTooltipPosition="above"
        [ngClass]="{ 'declined': faceVerification?.status !== 'verification.accepted', 'accepted': faceVerification?.status === 'verification.accepted' }"
        >

    @if (faceVerification?.status === 'verification.accepted') {
      <mat-icon>verified</mat-icon>
    } @else {
      <mat-icon>cancel</mat-icon>
    }
    <span>
      <span>{{ 'reports.identityVerification.idCardMatchConfidence' | translate }}</span><span class="match">{{faceVerification.faceMatchConfidence}}%</span>
    </span>
  </div>
}