import { Pipe, PipeTransform } from '@angular/core';
import { ReminderOption } from '../credit-edit/credit-communication/credit-note-edit/reminder-option';
import { ScoringConditionNode } from '@app/core/types/credit-score';

@Pipe({
  name: 'scoreMetadataNodeToDisplayText',
})
export class ScoreMetadataNodeToDisplayTextPipe implements PipeTransform {
  private RuleOperatorToSymbolMap = {
    'equal': '==',
    'notEqual': '!==',
    'lessThan': '<',
    'lessThanInclusive': '<=',
    'greaterThan': '>',
    'greaterThanInclusive': '>=',
    'booleanCoalesce': '=='
  }

  constructor() {}

  transform(node: ScoringConditionNode) {
    const factPath = node.path || '';
    const operator = this.RuleOperatorToSymbolMap[node.operator] || node.operator;
    const factResult = node.factResult !== `${node.fact}${factPath}` ? `: ${JSON.stringify(node.factResult)}` : '';
    let factDisplay = `${node.fact}${factPath}${factResult}`;
    let conditionValue: any = node.value;

    if (node?.value?.hasOwnProperty('fact')) {
        conditionValue = `${conditionValue['fact']}${conditionValue['path'] || ''}`;
    }

    if (typeof conditionValue === 'object') {
      if (conditionValue.hasOwnProperty('value')) {
        conditionValue = conditionValue.value;
      } else {
        // Could break the UI if it's too large
        // conditionValue = JSON.stringify(conditionValue);
        conditionValue = ''
      }
    }

    const displayText = `(${factDisplay}) ${operator} ${conditionValue}`;

    return displayText;
  }

}
