import { Client } from './client';
import { IdentityDocument } from './client-document';

export class IdentityReport<T> {
  time: string;
  id: string;
  source: string;
  type: string;
  civilId: string;
  data: T;
  metaData?: MetaData;
  status?: string;
  requesterName?: string;
  documents?: IdentityDocument[];
  __v: number;
}

export interface MetaData {
  statusCode: MetaStatusCode;
  reportDate: string;
  cacheTtl: number;
  rertId: number;
}
export enum MetaStatusCode {
  SUCCESS_REG = 'success_reg',
  NO_DATA_REG = 'no_data_reg',
  ERROR_REG = 'error_reg',
  ERROR_DG = 'error_dg',
  CONN_ERROR_REG = 'conn_error_reg',
  ACCESS_DENIED = 'access_denied',
  TEST = 'test',
  SUCCESS_DG = 'success_dg',
}

export interface Nssi7 {
  DataForEGNContracts: DataForEGNContracts[];
}

export interface DataForEGNContracts {
  EGN: string;
  FirstName: string;
  SurName: string;
  FamilyName: string;
  DateForEGNPersonalData: string;
  ContractCodeCorrection: number;
  ContractDocumentType: string;
  Bulstat: number;
  ContractReason: string;
  ContractNumber: number;
  ContractFoundationDate: string;
  ContractAddDate: string;
  ContractExpirationDate: string;
  EGNBaseSalary: number | null;
  EGNFunction_Description: string;
  EGNFunction_Code: number;
  EGNEconomicActivity_Description: string;
  EGNEconomicActivity_Code: number;
  ContractTerminationDate: string;
  ruso: number;
  DateInputInRUSO: string;
  DateInputInNSSI: string;
  CorrectOrIncorrect: string;
  InputNumber: string;
  EmployerName: string;
  EmployerAddress: string;
  EmployerPhone: string;
}

export interface Nssi7Summary {
  activeContracts: Nssi7SummaryContract[];
  terminatedContracts: Nssi7SummaryContract[];
  workExperience: number;
  workExperienceTotal: number;
}

export interface NssiAutomationScore {
  id: number;
  model_id: number;
  credit_id: number;
  civil_id: string;
  request_data: string;
  response_data: string;
  score: number;
  created_at: string;
  operator_id: number;
  operator_email: string;
  model_name: string;
}

export interface NssiAutomationScoreData {
  final: boolean;
  action: string;
  reason: string;
  dataFact: string;
}

export interface NssiAutomationScoreClientUpdate {
  employmentTypeId?: number;
  workPosition?: string;
  workExperience?: number;
  workExperienceTotal?: number;
  employerName?: string;
  employerId?: string;
  salary?: number;
  extraIncome?: number;
  workCity?: {
    id?: number;
    type?: string;
    name?: string;
    region?: {
      code: string;
      name: string;
    },
    municipality?: {
      code: string;
      name: string;
    }
  };
  workAddress?: string;
}

export enum NssiAutomationActionTypes {
  SUCCESS = 'success',
  ATTENTION = 'attention',
  ERROR = 'error'
}

export const NSSI_AUTOMATION_ACTION_MAPPER = {
  [NssiAutomationActionTypes.SUCCESS]: {
    color: '#23a800',
    icon: 'check_circle'
  },
  [NssiAutomationActionTypes.ATTENTION]: {
    color: '#ea7500',
    icon: 'error_outline'
  },
  [NssiAutomationActionTypes.ERROR]: {
    color: '#c9211e',
    icon: 'warning'
  },
};

export interface Nssi7SummaryContract {
  contractFoundationDate: string;
  contractTerminationDate: string;
  workExperience: number;
  bulstat: string;
  salary: number;
  workAddress: string;
  employerName: string;
  contractReason: string;
  workPosition: string;
}

export interface Nssi2 {
  PersonalInfo: PersonalInfo[];
  BulstatInfo: BulstatInfo[];
  EgnInfo: EgnInfo;
}

export interface PersonalInfo {
  correctionflag: string;
  Ruso: number;
  Bulstat: number;
  Year: number;
  Month: number;
  Typeofisured: string;
  Workdays: number;
  Salary: number;
  inputdate: string;
}

export interface BulstatInfo {
  Ruso?: number;
  Bulstat: number;
  Name?: string;
  address?: string;
}

export interface EgnInfo {
  EGN: string;
  familyname: string;
  City: string;
  Address: string;
  PostalCode: number;
}

export interface Nssi51 {
  Addition_or_Debt?: AdditionOrDebt[];
  Pensioner?: PensionerInfo[];
  Pensions?: PensionsInfo[];
}

export interface PensionerInfo {
  PensionerNameInfo: string;
  Value: string;
}

export interface PensionsInfo {
  PensionsDetails: string;
  Value: string;
}

export interface AdditionOrDebt {
  Details: string;
  Value: string;
}

export interface ListElement {
  id: string;
  time: Date;
  type: string;
}

export enum NssiTypes {
  NSSI7 = 'nssi7',
  NSSI2 = 'nssi2',
  NSSI51 = 'nssi51',
}

export enum CompensationTypes {
  DISABILITY_COMPENSATION = 'disability-compensation',
  UNEMPLOYMENT_COMPENSATION = 'unemployment-compensation'
}

export interface MaritalStatusReportData {
  MaritalStatusCode: number;
  MaritalStatusName: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  EGN: string;
  Gender: number;
}

export interface AddressReportData {
  DistrictCode: string;
  DistrictName: string;
  MunicipalityCode?: string;
  MunicipalityName?: string;
  SettlementCode?: string;
  SettlementName?: string;
  CityAreaCode?: string;
  CityArea?: string;
  LocationCode?: string;
  LocationName?: string;
  BuildingNumber?: number;
  Entrance?: string;
  Floor?: number;
  Apartment?: number;
  FromDate?: string;
  Address?: string;
  SearchDate?: string;
}

export interface PersonDataReportData {
  PersonNames: PersonNames;
  Alias: string;
  LatinNames: PersonNames;
  ForeignNames: PersonNames;
  Gender: Gender;
  EGN: string;
  BirthDate: string;
  PlaceBirth: string;
  BirthPlaceCode: string;
  Nationality: Nationality;
  DeathDate: string;
}

export class PersonNames {
  FirstName: string;
  SurName: string;
  FamilyName: string;
}

export class Gender {
  GenderCode: string;
  GenderName: string;
}

export interface MIReportData {
  civilId?: string;
  ActualStatusDate?: Date;
  BirthPlace?: {
    CountryCode: string;
    CountryName: string;
    TerritorialUnitName: string;
  };
  DLCategories?: string;
  DataForeignCitizen?: DataForeignCitizen;
  DocumentActualStatus?: string;
  DocumentStatusReason?: string;
  IdentityDocumentNumber?: string | number;
  IdentitySignature?: string;
  IssueDate?: string;
  IssuerName?: string;
  Nationality?: string;
  SecondNationality?: string;
  NationalityList?: Nationality[];
  PermanentAddress?: {
    DistrictName?: string;
    MunicipalityName?: string;
    SettlementCode?: number;
    SettlementName?: string;
    LocationCode?: number;
  };
  PersonNames?: {
    FirstName?: string;
    Surname?: string;
    FamilyName?: string;
  };
  Picture?: string;
  RPRemarks?: string;
  RPTypeofPermit?: string;
  ValidDate?: string;
  address?: string;
}

export class IdentityVerificationReportProofs {
  document: {
    proof: string;
    additional_proof: string
  };
  access_token: string;
  face: {
    proof: string
  };
  verification_video: string;
  verification_report: string;
}


export enum IdentityVerificationReportStatus {
  VERIFICATION_REQUESTED = 'verification.requested',
  VERIFICATION_DECLINED = 'verification.declined',
  VERIFICATION_ACCEPTED = 'verification.accepted',
}

export interface IdentityVerificationReportData {
  reference: string;
  event: IdentityVerificationReportStatus;
  country: string;
  proofs: IdentityVerificationReportProofs;
  verification_data: {
    document: {
      document_number: string;
      selected_type: string[];
      supported_types: string[];
      face_match_confidence: number;
    }
  };
  verification_result: {
    face: {};
    document: {
      document: number;
      document_visibility: number;
      document_must_not_be_expired: number;
      document_proof: number;
      selected_type: number;
      face_on_document_matched: number;
      document_number: number;
    }
  };
  info: {
    agent: {
      is_desktop: boolean;
      is_phone: boolean;
      device_name: string;
      browser_name: string;
      platform_name: string;
    };
    geolocation: {
      host: string;
      ip: string;
      rdns: string;
      asn: string;
      isp: string;
      country_name: string;
      country_code: string;
      region_name: string;
      region_code: number;
      city: string;
      postal_code: number;
      continent_name: string;
      continent_code: string;
      latitude: number;
      longitude: number;
      metro_code: string;
      timezone: string;
      ip_type: string;
      capital: string;
      currency: string;
    };
  };
  declined_reason: string;
  declined_codes: string[];
  services_declined_codes: {
    document: string[];
    face: string[]
  };
}

export interface FaceVerification {
  id: number;
  referenceId: string;
  createdAt: string;
  status: string;
  faceMatchConfidence: string;
  faceSourceId: number;
  documentSourceId: number;
  userCivilId: string;
}

export enum IdCardImageTypes {
  ID_FRONT = 'ID_FRONT',
  ID_BACK = 'ID_BACK',
  SELFIE = 'SELFIE'
}

export interface IdCardImage {
  image: any;
  name: string;
  id: number;
  url: any;
}

export interface DataForeignCitizen {
  PIN?: string;
  PN?: string;
  Names?: {
    FirstName?: string;
    Surname?: string;
    FamilyName?: string;
  };
  NationalityList?: Nationality[];
  Gender?: {
    GenderCode?: string;
    Cyrillic?: string;
  };
}

export interface Nationality {
  NationalityCode?: string;
  NationalityName?: string;
  NationalityName2?: string;
  NationalityCode2?: string;
}

export enum IDCardVerifiedTypes {
  VALID = 'ВАЛИДЕН',
  NOT_VALID = 'НЕВАЛИДЕН',
  NOT_VERIFIED = 'НЕВЕРЕФИЦИРАН',
}

export enum ClientMapperTypes {
  ID_CARD_NUMBER = 'idCardNumber',
  ID_CARD_VERIFIED = 'idCardVerified',
  ID_CARD_ISSUED_DATE = 'idCardIssuedDate',
  ID_CARD_ISSUER_NAME = 'idCardIssuer.name',
  EMAIL = 'email',
  MOBILE = 'mobile',
  ID_CARD_ISSUER = 'idCardIssuer',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  CURRENT_ADDRESS_CITY_NAME = 'currentAddress.city.name',
  CURRENT_ADDRESS_ADDRESS = 'currentAddress.address',
  CURRENT_ADDRESS_CITY = 'currentAddress.city',
  ID_CARD_ADDRESS_CITY_NAME = 'idCardAddress.city.name',
  ID_CARD_ADDRESS_CITY = 'idCardAddress.city',
  ID_CARD_ADDRESS_ADDRESS = 'idCardAddress.address',
  NATIONALITY_NAME = 'nationality.name',
  NATIONALITY = 'nationality',
  SECOND_NATIONALITY_NAME = 'secondNationality.name',
  SECOND_NATIONALITY = 'secondNationality',
  SALARY = 'salary',
  EMPLOYER_ID = 'employerId',
  EMPLOYER_NAME = 'employerName',
  EMPLOYMENT_STATUS = 'employmentStatus',
  EMPLOYMENT_TYPE = 'employmentType',
  EMPLOYMENT_TYPE_TYPE = 'employmentType.type',
  EDUCATION = 'education',
  EDUCATION_NAME = 'education.name',
  WORK_ADDRESS_ADDRESS = 'workAddress.address',
  WORK_POSITION = 'workPosition',
  MARITAL_STATUS = 'maritalStatus',
  MARITAL_STATUS_NAME = 'maritalStatus.name',
  EXTRA_INCOME = 'extraIncome'
}


export enum CreditMapperTypes {
  PRINCIPAL = 'principal',
  PERIOD = 'period',
  INSTALLMENTS_NUMBER = 'installmentsNumber',
  PRODUCT = 'product',
  PRODUCT_PLAN_NAME = 'product.name',
}

export enum ClientMIMapperTypes {
  IDENTITY_DOCUMENT_NUMBER = 'IdentityDocumentNumber',
  DOCUMENT_ACTUAL_STATUS = 'DocumentActualStatus',
  ISSUE_DATE = 'IssueDate',
  ISSUER_NAME = 'IssuerName',
  PERSONAL_NAMES_FIRST_NAME = 'PersonNames.FirstName',
  PERSONAL_NAMES_SURNAME = 'PersonNames.Surname',
  PERSONAL_NAMES_FAMILY_NAME = 'PersonNames.FamilyName',
  PERMANENT_ADDRESS_SETTLEMENT_NAME = 'PermanentAddress.SettlementName',
  ADDRESS = 'address',
  NATIONALITY_NAME = 'Nationality.NationalityName',
  SECOND_NATIONALITY_NAME = 'SecondNationality.NationalityName',
}


export enum ClientNSSI2MapperTypes {
  FAMILY_NAME = 'familyname',
  BULSTAT = 'Bulstat',
  SALARY = 'Salary',
  CITY = 'City',
  ADDRESS = 'Address',
  EMPLOYER_NAME = 'Name',
  WORK_ADRESS = 'address'
}


export enum ClientNSSI7MapperTypes {
  FIRST_NAME = 'FirstName',
  SUR_NAME = 'SurName',
  FAMILY_NAME = 'FamilyName',
  EGN_BASE_SALARY = 'EGNBaseSalary',
  BULSTAT = 'Bulstat',
  EMPLOYER_NAME = 'EmployerName',
  EMPLOYER_ADDRESS = 'EmployerAddress',
  EGN_FUNCTION_DESCRIPTION = 'EGNFunction_Description',
}

export enum ClientNSSI51MapperTypes {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  ADDRESS = 'address',
  TOTAL_PENSION_AMOUNT = 'totalAmount'
}

export enum ClientMaritalStatusMapperTypes {
  FIRST_NAME = 'FirstName',
  MIDDLE_NAME = 'MiddleName',
  LAST_NAME = 'LastName',
  MARITAL_STATUS_NAME = 'MaritalStatusName'
}

export enum ClientPersonDataMapperTypes {
  FIRST_NAME = 'FirstName',
  MIDDLE_NAME = 'SurName',
  LAST_NAME = 'FamilyName',
  NATIONALITY_NAME = 'Nationality.NationalityName',
  SECOND_NATIONALITY_NAME = 'Nationality.NationalityName2'
}

export enum ClientAddressMapperTypes {
  SETTALMENT_NAME = 'SettlementName',
  ADDRESS = 'Address',
}

export enum GenderTypes {
  MALE = 'Мъж',
  FEMALE = 'Жена'
}

export enum Nssi51PensionerValueTypes {
  FIRST_NAME = 'Име',
  MIDDLE_NAME = 'Презиме',
  LAST_NAME = 'Фамилия',
  ADDRESS = 'Адрес',
  TOTAL_PENSION_AMOUNT = 'Общ размер'
}

export enum ClientMaritalStatusTypes {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  DIVORCED = 'DIVORCED',
  COHABITING = 'COHABITING'
}

export enum ReportTypes {
  CCR = 'CCR',
  NSSI = 'NSSI',
  MARITAL_STATUS = 'MARITAL_STATUS',
  COMPENSATION = 'COMPENSATION',
  PERSON_DATA = 'PERSON_DATA',
  PERMANENT_ADDRESS = 'PERMANENT_ADDRESS',
  TEMPORARY_ADDRESS = 'TEMPORARY_ADDRESS',
  GRAO_REPORTS = 'GRAO_REPORTS',
}

export enum IdentifierType {
  EGN = 'ЕГН',
  LCHK = 'ЛЧК'
}

export interface UnemploymentCompensationReportData {
  Identifier: number;
  IdentifierType: IdentifierType;
  Names: string;
  PaymentByDate: PaymentByDate[];
  MissingData: string;
  dateFrom: string;
  dateTo: string;
  status: string;
}

export interface PaymentByDate {
  RegNumberDate: string;
  PaymentsByType: UnemploymentPaymentByType[];
}

export interface UnemploymentPaymentByType {
  BenefitType: string;
  Payments: UnemploymentPayment[];
}

export interface UnemploymentPayment {
  BenefitMonth: string;
  BenefitYear: number;
  BenefitAmount: number;
}

export interface DisabilityCompensationReportData {
  Identifier: number;
  IdentifierType: IdentifierType;
  Names: string;
  PaymentData: DisabilityPayment[];
  MissingData: string;
  dateFrom: string;
  dateTo: string;
}

export interface DisabilityPayment {
  BenefitType: string;
  BenefitMonth: string;
  BenefitYear: string;
  BenefitAmount: number;
  BenefitDatePayment: string;
}

export interface ReportConfig {
  clientMapper: { [key: string]: string };
  reportData: any;
  client: Client;
  criticalProperties?: any[];
  notUpdatableProperties?: any[];
  processReportData?: (reportData: any) => any;
}
